<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form
          v-else
          novalidate
        >
          <b-row>

            <b-col
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('name')"
                vid='name'
                rules='required'
              >
                <b-form-group
                  :label="$t('name')"
                >
                  <b-form-input
                    v-model='form.name'
                    :placeholder="$t('name')"
                    autocomplete='off'
                    type='text'
                    disabled
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('description')"
                vid='description'
                rules='required'
              >
                <b-form-group
                  :label="$t('description')"
                >
                  <b-form-textarea
                    v-model='form.description'
                    :placeholder="$t('description')"
                    autocomplete='off'
                    rows='5'
                    disabled
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('mobile')"
                vid='mobile'
                rules='required'
              >
                <b-form-group
                  :label="$t('mobile')"
                >
                  <b-form-input
                    v-model='form.mobile'
                    :placeholder="$t('mobile')"
                    autocomplete='off'
                    type='text'
                    disabled
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('email')"
                vid='email'
                rules='required'
              >
                <b-form-group
                  :label="$t('email')"
                >
                  <b-form-input
                    v-model='form.email'
                    :placeholder="$t('email')"
                    autocomplete='off'
                    type='text'
                    disabled
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('service')"
                vid='service'
                rules='required'
              >
                <b-form-group
                  :label="$t('service')"
                >
                  <b-form-input
                    v-model='form.service'
                    :placeholder="$t('service')"
                    autocomplete='off'
                    type='text'
                    disabled
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>


          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { websiteMainUrl } from '@/services/url.service'

export default {
  mixins: [formMixin],
  data() {
    return {

      form: {
        name: null,
        description: null,
        mobile: null,
        service: null,
        email: null,
      },

      isDataLoaded: false,
    }
  },
  created() {

    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },

  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name,
            mobile: data.mobile,
            service: data.service,
            description: data.description,
            email: data.email,
          }

          this.isDataLoaded = true
        })
    },
  },

}
</script>
